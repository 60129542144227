<template>
  <div class="hello">
    <div class="pick_up">
      <h2>
      MENU
      </h2>
      <p>
      目次
      </p>
    </div>

    <div class="wrap">
      <div class="item">
        <router-link to="/charge" class="item_router">
          <img src="../assets/paid.png">
          <h2>作業工賃</h2>
        </router-link>
      </div>

      <div class="item">
        <router-link to="/flow" class="item_router">
          <img src="../assets/store.png">
          <h2>受付の流れ</h2>
        </router-link>
      </div>

      <div class="item">
        <router-link to="/instagram" class="item_router">
          <img src="../assets/photo.png">
          <h2>取付事例</h2>
        </router-link>
      </div>

      <div class="item">
        <router-link to="/open" class="item_router">
          <img src="../assets/open.png">
          <h2>営業時間</h2>
        </router-link>
      </div>

      <div class="item">
        <router-link to="/access" class="item_router">
          <img src="../assets/maps.png">
          <h2>行き方</h2>
        </router-link>
      </div>

      <div class="item">
        <router-link to="/faq" class="item_router">
          <img class="faq_img" src="../assets/faq.png">
          <h2>ご質問</h2>
        </router-link>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only padding-top6rem-->
<style scoped>
.faq_img{
  width: 300;
  height: 100%;
}
.hello{
  padding-top: 6rem;
  padding-bottom: 10rem;
}

.pick_up h2{
  letter-spacing: 0.05em
}
.pick_up p{
  font-weight: 600;
  font-size: 14px;
  margin-top: -15px;
  padding-bottom: 20px;
  letter-spacing: 0.2em
}

.section a{
  color: black;
}
.about_summary{
  text-decoration: none;
  color: black;
}
.hello_p{
  padding-top: 8px;
}


.oneArea {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 1024px;
  margin: 50px auto;
  padding: 1em 10px;
}
.oneArea .onebox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.oneArea .onebox:nth-child(even) {
  flex-direction: row-reverse;
  margin-top: 20px;
}
.oneArea .onebox .imgArea {
  width: 16%;
  position: relative;
}
.oneArea .onebox:nth-child(odd) .imgArea img {
  width: 80%;
  max-width: 130px;
  position: absolute;
  top: -20px;
  left: 1.5rem;
  padding-right: 30px;
}
.oneArea .onebox:nth-child(even) .imgArea img {
  width: 80%;
  max-width: 130px;
  position: absolute;
  top: -20px;
  left: auto;
  right: -0.5rem;
  padding-left: 30px;
  transform: scale(-1, 1);
}
.oneArea .onebox .fukiArea {
  width: 63%;
  padding-bottom: 2em;
  text-align:left;
}
.oneArea .onebox .fukidasi {
  width: 100%;
  position: relative;
  padding: 25px;
  background-color: #fde5e5;
  font-size: 18px;
  color: #231815;
  border-radius: 12px;
  box-sizing: border-box;
}
.oneArea .onebox .fukidasi::before {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  transform: rotate(45deg);
  top: 22px;
  border-right: 25px solid transparent;
  border-bottom: 25px solid transparent;
}
.oneArea .onebox .fukidasi::after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  transform: rotate(45deg);
  top: 40px;
  border-right: 25px solid transparent;
  border-bottom: 25px solid transparent;
}
.oneArea .onebox:nth-child(odd) .fukidasi::before {
  left: -15px;
  border-left: 25px solid #fde5e5;
  border-top: 25px solid transparent;
}
.oneArea .onebox:nth-child(odd) .fukidasi::after {
  left: -25px;
  border-left: 25px solid #f9f8f4;
  border-top: 25px solid transparent;
}
.oneArea .onebox:nth-child(even) .fukidasi {
  background-color: #f2f3f7;
}
.oneArea .onebox:nth-child(even) .fukidasi::before {
  left: auto;
  right: -15px;
  border-left: 25px solid transparent;
  border-top: 25px solid #f2f3f7;
}
.oneArea .onebox:nth-child(even) .fukidasi::after {
  left: auto;
  right: -25px;
  border-left: 25px solid transparent;
  border-top: 25px solid #f9f8f4;
}
@media screen and (max-width: 1024px) {
  .oneArea .onebox:nth-child(odd) .imgArea img {
    max-width: 100%;
    top: 0;
  }
  .oneArea .onebox:nth-child(even) .imgArea img {
    max-width: 100%;
    transform: scale(-1, 1);
    top: 0;
  }
  .oneArea .onebox .fukidasi {
    padding: 15px;
    font-size: 14px;
  }
  .oneArea .onebox .fukidasi::before {
    top: 8px;
  }
  .oneArea .onebox .fukidasi::after {
    top: 20px;
  }
}
@media screen and (max-width: 420px) {
  .oneArea {
    margin: 30px auto;
  }
  .oneArea .onebox:nth-child(even) {
    margin-top: 15px;
  }
  .oneArea .onebox .imgArea {
    width: 28%;
  }
  .oneArea .onebox .fukidasi {
    padding: 18px 15px;
    font-size: 0.9rem;
    line-height: 2rem;
    font-weight: bold;
  }
}


.wrap {
  display: flex;
  flex-wrap: wrap;
}

.item {
  padding: 30px;
  width: 32%;
  background-color: white;
  border-radius: 10px;
  border-radius: 50px;
background: #f9f8f4;
box-shadow:  22px 22px 45px #d4d3cf,
             -22px -22px 45px #ffffff;
}

.item:not(:nth-child(3n+3)) {
  margin-right: 2%;
}

.item:nth-child(n+4) {
  margin-top: 30px;
}


.item h2{
  font-size: 0.7rem;
  color: black;
}
.item_router{
  text-decoration: none;
  display: inline-block;
}

@media screen and (max-width: 519px) {
  .item img{
    width: 50%;
  }
}
@media screen and (min-width: 520px) {
  .item img{
    width: 20%;
  }
}
</style>
