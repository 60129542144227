<template>
  <div class="tirescroll">
    <div class="pick_up">
      <h2>
      NEWS
      </h2>
      <p>
      最新情報
      </p>
    </div>
    <div class="slide-wrap">
      <div class="slide-box">
        <router-link to="/winterbs">
        <img class="img_top" src='../assets/winterbs.webp'>
        </router-link>
      </div>
      <div class="slide-box">
        <router-link to="/recruiting">
        <img class="img_top" src='../assets/job_tel.webp'>
        </router-link>
      </div>
      <div class="slide-box">
        <router-link to="/gcoin">
        <img class="img_top" src='../assets/gcoin.webp'>
        </router-link>
      </div>
      <div class="slide-box">
        <router-link to="/customwheels">
        <img class="img_top" src='../assets/wheelchange.webp'>
        </router-link>
      </div>
      <!--
      <div class="slide-box">
        <router-link to="/winter-to-summer">
        <img class="img_top" src='../assets/wintertosummer.webp'>
        </router-link>
      </div>
      -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<style scoped>
.hello{
  padding-bottom: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.large{
  font-size: 1.5rem;
  font-weight: 600;
}
.message{
  text-align: center;
  line-height: 2rem;
  font-weight: 500;
  padding-top: 8rem;
  padding-bottom: 0rem;
}
.img_top{
  width: 100%;
  z-index: 1;
}
.pick_up h2{
  letter-spacing: 0.05em
}
.pick_up p{
  font-weight: 600;
  font-size: 14px;
  margin-top: -15px;
  padding-bottom: 20px;
  letter-spacing: 0.2em
}
.pick_up{
  padding-top: 8rem;
}

.slide-wrap {
  background-color: #f9f8f4;
  display: flex;
  margin: 0 auto;
  max-width: 1024px;
  width: 100%;
}
.slide-box {
  height: auto;
  margin: .5%;
  width: 24%;
}
.slide-box a {
  background-color: #f9f8f4;
  color: #222;
  display: block;
  text-decoration: none;
}
.slide-box img {
  display: block;
  height: auto;
  width: 100%;
}
.slide-box p {
  font-weight: bold;
  padding: 10px;
  margin:.5rem auto;
}

.slide-wrap {
  overflow-x: scroll;
}
.slide-box {
  flex: 0 0 70%;
  margin: auto 1% auto auto;
}
</style>
