<template>
  <div id="app">
  
  <!--
  <Snow :wind='1' :swing='5' speed='l'/>
  -->

    <div class="hamburger_btn" v-on:click='ActiveBtn=!ActiveBtn'>
      <span class="line line_01" v-bind:class="{'btn_line01':ActiveBtn}"></span>
      <span class="line line_03" v-bind:class="{'btn_line03':ActiveBtn}"></span>
    </div>
    <transition name="menu">
      <div class="menu" v-show="ActiveBtn" v-on:click='ActiveBtn=!ActiveBtn'>
          <ul>
              <router-link to="/" class="animate-link"><li><img src="./assets/home.png">ホーム</li></router-link>

              <router-link to="/charge"><li><img src="./assets/paid.png">交換費用</li></router-link>

              <router-link to="/flow"><li><img src="./assets/store.png">受付の流れ</li></router-link>
              <router-link to="/instagram"><li><img src="./assets/photo.png">お取り付け事例</li></router-link>
              <router-link to="/open"><li><img src="./assets/open.png">営業時間</li></router-link>
              <router-link to="/access"><li><img src="./assets/maps.png">行き方</li></router-link>
              <router-link to="/about"><li><img src="./assets/bussiness.png">会社概要</li></router-link>
              <router-link to="/faq"><li><img src="./assets/faq.png">よくあるご質問</li></router-link>
          </ul>
      </div>
    </transition>

    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
    ActiveBtn: false
    }
  }
}
/*
import Snow from 'vue-niege';
export default {
  name: 'HomeView',
  data(){
    return{
    ActiveBtn: false
    }
  },
  components: {
    Snow,
  }
}
*/
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
/*ボタン*/
.hamburger_btn {
  position: fixed; /*常に最上部に表示したいので固定*/
  top: 0;
  right: 0;
  width: 80px;
  height: 72px;
  cursor: pointer;
  z-index: 50;
}

.hamburger_btn .line {
  position: absolute;
  top: 0;
  left: 20px;
  width: 32px;
  height: 3px;
  background: #789691;
  text-align: center;
}

.hamburger_btn .line_01 {
  top: 26px;
  transition: 0.4s ease;
}
.hamburger_btn .line_03 {
  top: 36px;
  transition: 0.4s ease;
}


.btn_line01 {
  transform: translateY(5.2px) rotate(-45deg);
  transition: 0.4s ease;
}
.btn_line03 {
  transform: translateY(-5.2px) rotate(45deg);
  transition: 0.4s ease;
}

/*サイドバー*/
.menu-enter-active, .menu-leave-active {
  transition: opacity 0.4s;
}
.menu-enter, .menu-leave-to {
  opacity: 0;
}
.menu-leave, .menu-enter-to{
  opacity: 1;
}

.menu li {
  list-style: none;
  line-height: 2.5;
  padding-top: 1rem;
}
.menu {
  background-color: rgba(197, 197, 197, 1.771); /*#90be70 #789691*/
  z-index: 30;
  padding: 2rem 1rem;
  position: fixed;
  width: 19rem;
  height: 80rem;
  top: 0;
  right: 0;
  text-align:left;
}
.menu a {
  color: rgb(66, 66, 66);
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: bold;
}
.menu ul{
  padding-top: 2rem;
  padding-left: 1.5rem;
}
.menu img{
  width: 12%;
  height: 12%;
  margin-top: -0.3rem;
  padding-right: 0.4rem;
}


.animate-link {
  text-decoration: none; /* デフォルトの下線を非表示にする */
  color: #0086e0; /* 文字色 */
  background-image: linear-gradient(90deg, #0086e0, #0086e0); /* 線の色 */
  background-repeat: no-repeat;
  background-position: left bottom; /* 線の起点を左・下に設定 */
  background-size: 0 1px; /* 線の横幅を0、縦幅を1pxに */
  transition: background-size 0.01s; /* 線を伸ばすアニメーション実行時間を指定 */
}

.animate-link:hover {
  background-size: 100% 1px; /* 線の横幅を100%にする */
}



</style>
