<template>
  <div class="hello">
    <img class="img_top" alt="Instagram" src="../assets/firstcamry.webp">
    <div class="flame01">
      <div :class="{fadeIn1:visible1}" v-show="visible1">
        <p class="instagram_title">
          {{msg}}
          <span class="instagram_line">
          |
          </span>
          <span class="instagram_fav">
          ♡72
          </span>
        </p>
        <p class="instagram_comment">
          TOYOTAカムリに
          19インチのNITTOニットータイヤNT555G2と
          WORK ワークのホイールVS-XXブイエスダブルエックス
          color/ライトグレイッシユシルバーを
          お取り付けさせていただきました✨
          
          WORKのホイールVS-XXは
          3ピース構造のメッシュホイールです。
          気品ある存在感抜群のフェイスバランス、
          リムに打ち込まれたインパクトあるピアスボルトが
          ディープリムに鮮明に映り込む美しさがなんとも芸術的です✨
          トレンドに流される事のないプレミアムな世界観✨
          いつ見ても普遍的なカッコ良さです‼︎
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  props: {
    msg: String
  },
  data(){
    return{
    visible: false,
    visible1: false,
    }
  },
  methods:{
    handleScroll() {
      if (!this.visible) {
        this.visible = window.scrollY > 100;
      }else if(window.scrollY < 90){
        this.visible = !this.visible;
      }
    },
    handleScroll1() {
      if (!this.visible1) {
        this.visible1 = window.scrollY > 3100;
      }else if(window.scrollY < 3090){
        this.visible1 = !this.visible;
      }
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("scroll", this.handleScroll1);
  },
  destroyed() {
      window.removeEventListener("scroll", this.handleScroll);
      window.removeEventListener("scroll", this.handleScroll1);
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello{
  padding-bottom: 100px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.about_summary{
  text-decoration: none;
  color: black;
}
.hello_p{
  padding-top: 8px;
}


.img_top{
  width: 100%;
  height: 100%;
  z-index: 1;
}
.flame01 {
  margin-top: -30px;
  margin-left: -30px;
  position: relative;
  z-index: 6;
  padding-left: 50px;
  padding-top: 10px;
  padding-bottom: 50px;
  padding-right: 20px;
  width: 100%;
  background-color: #ffffff;
  text-align:left;
}
.instagram_fav{
  font-size: 10px;
  font-weight: bold;
  margin-right: 30px;
}
.instagram_title{
  font-size: 20px;
  font-weight: 800;
  letter-spacing : 0.1rem;
}
.instagram_line{
  font-size:20px;
  font-weight: 100;
}
.instagram_comment{
  font-size: 0.8rem;
  font-weight: bold;
  line-height: 1.8;
  letter-spacing: 0.02em;
}

.fadeIn {
  animation: fadeIn 1s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(200px);
    transition-duration:.7s;
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
    transition-duration:.7s;
  }
}
.fadeIn1 {
  animation: fadeIn1 1s;
}
@keyframes fadeIn1 {
  0% {
    opacity: 0;
    transform: translateY(200px);
    transition-duration:.7s;
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
    transition-duration:.7s;
  }
}
</style>
