<template>
  <div>
    <img class="img_top" :src="'https://instagram-taiyakan.s3.amazonaws.com/instagram/'+imgnumber+'.jpg'">
    <div class="hello">
      <div class="flame01">
      <iframe class="iframe_class" :src="'https://instagram-taiyakan.s3.amazonaws.com/instagram/'+src_data+'.html?wmode=transparent'"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return{
    src_data: this.src_data_props,
    imgnumber: this.imgnumber_props
    }
  },
  props: {
    msg: String,
    src_data_props: String,
    imgnumber_props: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello{
  padding-bottom: 150px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.about_summary{
  text-decoration: none;
  color: black;
}
.hello_p{
  padding-top: 8px;
}


.img_top{
  width: 100%;
  z-index: 1;
}
.flame01 {
  margin-top: -30px;
  margin-left: 30px;
  position: relative;
  z-index: 0;
  padding-left: 40px;
  padding-top: 25px;
  padding-bottom: 50px;
  padding-right: 55px;
  width: 100%;
  background-color: #ffffff;
  text-align:left;
}
.iframe_class{
  width: 100%;
  height: 29vh;
  padding-top: 0rem;
  padding-bottom: 0rem;
}

</style>
