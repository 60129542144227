import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/carryon',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutCustomerSuppliedTires.vue'),
    redirect: '/CustomerSuppliedTires'
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutFaq.vue')
  },
  {
    path: '/open',
    name: 'open',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutOpen.vue')
  },
  {
    path: '/charge',
    name: 'charge',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutCharge.vue')
  },
  {
    path: '/instagram',
    name: 'instagram',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutInstagram.vue')
  },
  {
    path: '/flow',
    name: 'flow',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutFlow.vue')
  },
  {
    path: '/access',
    name: 'access',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutAccess.vue')
  },
  {
    path: '/nh200',
    name: 'nh200',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutNh200.vue')
  },
  {
    path: '/seiberling',
    name: 'seiberling',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutSeiberling.vue')
  },
  {
    path: '/lx100',
    name: 'lx100',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutLx100.vue')
  },
  {
    path: '/pxrv2',
    name: 'pxrv2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutPxrv2.vue')
  },
  {
    path: '/at002',
    name: 'at002',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutAt002.vue')
  },
  {
    path: '/grx3',
    name: 'grx3',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutGrx3.vue')
  },
  {
    path: '/luftrv2',
    name: 'luftrv2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutLuftrv2.vue')
  },
  {
    path: '/leggera',
    name: 'leggera',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutLeggera.vue')
  },
  {
    path: '/re004',
    name: 're004',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutRe004.vue')
  },
  {
    path: '/rd604',
    name: 'rd604',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutRd604.vue')
  },
  {
    path: '/others',
    name: 'others',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutOthers.vue')
  },
  {
    path: '/grv2',
    name: 'grv2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutGrv2.vue')
  },
  {
    path: '/hl850',
    name: 'hl850',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutHl850.vue')
  },
  {
    path: '/re71rs',
    name: 're71rs',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutRe71rs.vue')
  },
  {
    path: '/s007a',
    name: 's007a',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutS007a.vue')
  },
  {
    path: '/sale',
    name: 'sale',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutSale.vue')
  },
  {
    path: '/gcoin',
    name: 'gcoin',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutGcoin.vue')
  },
  {
    path: '/wheelalignment',
    name: 'wheelalignment',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutWheelalignment.vue')
  },
  {
    path: '/suspension',
    name: 'suspension',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutSuspension.vue')
  },
  {
    path: '/CustomerSuppliedTires',
    name: 'CustomerSuppliedTires',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutCustomerSuppliedTires.vue')
  },
  {
    path: '/engineoil',
    name: 'engineoil',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutEngineoil.vue')
  },
  {
    path: '/winterstart',
    name: 'winterstart',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutWinter.vue')
  },
  {
    path: '/vrx3',
    name: 'vrx3',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutVrx3.vue')
  },
  {
    path: '/vrx2',
    name: 'vrx2',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutVrx2.vue')
  },
  {
    path: '/icepartner2',
    name: 'icepartner2',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutIcepartner2.vue')
  },
  {
    path: '/dmv3',
    name: 'dmv3',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutDmv3.vue')
  },
  {
    path: '/rft',
    name: 'rft',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutRft.vue')
  },
  {
    path: '/waiting',
    name: 'waiting',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutWaitingtime.vue')
  },
  {
    path: '/aboutstatus',
    name: 'aboutstatus',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutStatus.vue')
  },
  {
    path: '/winterbs',
    name: 'winterbs',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutWinterbs.vue')
  },
  {
    path: '/newno',
    name: 'newno',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutNewno.vue')
  },
  {
    path: '/Wheelstock',
    name: 'Wheelstocks',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutWheelstock.vue')
  },
  {
    path: '/ppx',
    name: 'ppx',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutPpx.vue')
  },
  {
    path: '/cvx',
    name: 'cvx',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutCvx.vue')
  },
  {
    path: '/t10',
    name: 't10',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutT10.vue')
  },
  {
    path: '/schwertsc5',
    name: 'schwertsc5',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutSchwertsc5.vue')
  },
  {
    path: '/brocken',
    name: 'brocken',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutBrocken.vue')
  },
  {
    path: '/lmx',
    name: 'lmx',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutLmx.vue')
  },
  {
    path: '/otherwheels',
    name: 'otherwheels',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutOtherwheels.vue')
  },
  {
    path: '/alenza001',
    name: 'alenza001',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutAlenza001.vue')
  },
  {
    path: '/outletsale',
    name: 'outletsale',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutOutletsale.vue')
  },
  {
    path: '/warwick',
    name: 'warwick',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutWarwick.vue')
  },
  {
    path: '/tr10',
    name: 'tr10',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutTR10.vue')
  },
  {
    path: '/journey',
    name: 'journey',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutJourney.vue')
  },
  {
    path: '/customwheels',
    name: 'customwheels',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutCustomwheels.vue')
  },
  {
    path: '/leonis',
    name: 'leonis',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutLeonis.vue')
  },
  {
    path: '/mudvancextypes',
    name: 'mudvancextypes',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutMudvancextypes.vue')
  },
  {
    path: '/ppxblackwhite',
    name: 'ppxblackwhite',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutPpxblackwhite.vue')
  },
  {
    path: '/wheelprice2024',
    name: 'wheelprice2024',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutWheelPriceIncrease2024.vue')
  },
  {
    path: '/recruiting',
    name: 'recruiting',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutRecruiting.vue')
  },
  {
    path: '/puncture',
    name: 'puncture',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutPuncture.vue')
  }
]


//{
  //path: '/recruiting',
  //name: 'recruiting',
  //component: () => import(/* webpackChunkName: "about" */ '../views/AboutRecruiting.vue')
//}


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
       return savedPosition
    } else {
       return { x: 0, y: 0 }
		}
	},
  routes: routes
})

export default router
