<template>
  <div class="hello">
    <img class="img_top" alt="Instagram" src='../assets/third.jpg'>
    <div class="flame01">
      <div :class="{fadeIn1:visible1}" v-show="visible1">
        <p class="instagram_title">
          {{msg}}
          <span class="instagram_line">
          |
          </span>
          <span class="instagram_fav">
          ♡54
          </span>
        </p>
        <p class="instagram_comment">
        トヨタ スープラに
        20インチのRAYS/グラムライツ
        57FXZのタイヤセット&
        MAX ORIDOのフルエアロキット&
        HKSの車高調を装着させていただきました✨

        全日本ドリフト選手権に参戦する
        レーサー川畑真人選手が
        D1グランプリでA90スープラを
        デビューさせるのに合わせて
        開発されたのが57FXZだそうです❗️
        大口径がコンケーブデザインを
        引き立たせてとっても素敵ですね✨

        S.S様、ありがとうございました😊
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  props: {
    msg: String
  },
  data(){
    return{
    visible: false,
    visible1: false,
    }
  },
  methods:{
    handleScroll() {
      if (!this.visible) {
        this.visible = window.scrollY > 900;
      }else if(window.scrollY < 890){
        this.visible = !this.visible;
      }
    },
    handleScroll1() {
      if (!this.visible1) {
        this.visible1 = window.scrollY > 4700;
      }else if(window.scrollY < 4690){
        this.visible1 = !this.visible;
      }
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("scroll", this.handleScroll1);
  },
  destroyed() {
      window.removeEventListener("scroll", this.handleScroll);
      window.removeEventListener("scroll", this.handleScroll1);
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello{
  padding-bottom: 100px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.about_summary{
  text-decoration: none;
  color: black;
}
.hello_p{
  padding-top: 8px;
}


.img_top{
  width: 100%;
  height: 100%;
  z-index: 1;
}
.flame01 {
  margin-top: -30px;
  margin-left: -30px;
  position: relative;
  z-index: 0;
  padding-left: 50px;
  padding-top: 10px;
  padding-bottom: 50px;
  padding-right: 20px;
  width: 100%;
  background-color: #ffffff;
  text-align:left;
}
.instagram_fav{
  font-size: 10px;
  font-weight: bold;
  margin-right: 30px;
}
.instagram_title{
  font-size: 20px;
  font-weight: 800;
  letter-spacing : 0.1rem;
}
.instagram_line{
  font-size:20px;
  font-weight: 100;
}
.instagram_comment{
  font-size: 0.8rem;
  font-weight: bold;
  line-height: 1.8;
  letter-spacing: 0.02em;
}

.fadeIn {
  animation: fadeIn 1s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(200px);
    transition-duration:.7s;
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
    transition-duration:.7s;
  }
}
.fadeIn1 {
  animation: fadeIn1 1s;
}
@keyframes fadeIn1 {
  0% {
    opacity: 0;
    transform: translateY(200px);
    transition-duration:.7s;
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
    transition-duration:.7s;
  }
}
</style>
