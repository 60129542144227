<template>
  <div class="crowded">
    <h1>
    作業待ち状況
    </h1>
    <img class="img_wait" alt="待ち状況" src="../assets/car.png">
    <span>
    {{open}}
    ( {{date_time}} 現在 )
    </span>
    <p>
    <iframe class="iframe_class" id="wait" src="https://instagram-taiyakan.s3.amazonaws.com/raspberry/waiting.html"></iframe>
    </p>

  </div>
</template>

<script>

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data(){
    return{
      date_time: '',
      open: '',
      status: ''
      }
    },
  mounted: function() {
    this.updateTime();
    setInterval(this.updateTime, 60000);
    document.getElementById('wait').contentWindow.location.reload();
    },
  methods:{
    updateTime: function() {
    var toDoubleDigits = function(num) {
    num += "";
    if (num.length === 1) {
      num = "0" + num;
    }
    return num;
    };
    let currentdate = new Date()
    this.date_time = currentdate.getMonth()+1 +'/'+currentdate.getDate()+' '+currentdate.getHours() + ':' + toDoubleDigits(currentdate.getMinutes())
    if (parseInt(currentdate.getHours()) >= 10 && parseInt(currentdate.getHours()) < 19){
      this.open = "営業中"
      this.status = "比較的スムーズにご案内可能です。"
    }else{
      this.open = "準備中"
      this.status = "作業受付は10:00からです。"
    }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.crowded{
  padding-top: 180px;
  padding-bottom: 150px;
}
.crowded p{
  padding-top: 0.6rem;
  margin-bottom: -6rem;
  font-weight: 600;
}
.crowded h1{
  letter-spacing: 0.12em;
  font-size: 1.6rem;
}
.crowded span{
  font-size: 0.8rem;
  font-weight: 500;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}


@media screen and (max-width: 519px) {
  .img_wait{
    width: 25%;
    z-index: 1;
  }
  .img_reload{
    width: 5%;
  }
}
@media screen and (min-width: 520px) {
  .img_wait{
    width: 15%;
    z-index: 1;
  }
  .img_reload{
    width: 3%;
  }
}
.flame01 {
  margin-top: -30px;
  margin-left: -30px;
  position: relative;
  z-index: 6;
  padding-left: 50px;
  padding-top: 10px;
  padding-bottom: 50px;
  padding-right: 20px;
  width: 100%;
  background-color: #ffffff;
  text-align:left;
}


</style>
