<template>
  <div class="hello">
    <p class="message">
    <span class="large">タイヤ館 御殿場</span> は<br>
    「親から子へ何世代にも渡って安心して紹介できるお店」を目指しています。
    タイヤ選びでお困りのことがありましたら私たちにご相談ください！<br>
    御殿場、箱根、小山のカーライフを25年以上サポートしてきた当店では、信頼の「ブリヂストンタイヤ」をベテランのスタッフが作業いたします。<br><br>
    タイヤは大切なご家族、ご友人、ご自身の安全に関わる重要な部品です。
    タイヤメーカー・取付店舗は時間をかけて選択することをおすすめします！
    </p>
    <div class="bg"></div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<style scoped>
.hello{
  padding-bottom: 9rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.large{
  font-size: 1.5rem;
  font-weight: 600;
}
.message{
  text-align: left;
  line-height: 2rem;
  font-weight: 500;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-image: repeating-linear-gradient(-45deg, #789691 0px, #789691 1px, rgba(0 0 0 / 0) 0%, rgba(0 0 0 / 0) 50%),repeating-linear-gradient(-45deg, #789691 0px, #789691 1px, rgba(0 0 0 / 0) 0%, rgba(0 0 0 / 0) 50%);
  background-size: 4px 4px;
  background-repeat: repeat-x;
  background-position: center top, center bottom;
}

</style>
